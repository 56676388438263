const roles = [
	'Administrator',
	'Account Manager',
	'Customer Buyer',
	'Customer Driver',
	'Prospect',
	'Customer Administrator',
	'Supplier'
]

export default roles;
